<template>
  <router-view></router-view>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
  computed: {
    ...mapGetters(['getPreSelectedChannel', 'getPreSelectedLoc']),
  },
  beforeDestroy() {
    if (this.getPreSelectedChannel || this.getPreSelectedLoc) {
      this.clearPreSelectedItem();
    }
  },
  methods: {
    ...mapMutations(['SET_PRE_SELECTED_CHANNEL', 'SET_PRE_SELECTED_LOC']),
    clearPreSelectedItem() {
      this.SET_PRE_SELECTED_CHANNEL(null);
      this.SET_PRE_SELECTED_LOC(null);
    },
  },
};
</script>
